<template lang="pug">
  .recommended-price(:class="priceColorClassName")
    | {{ formattedRecommendedPrice }}
</template>

<script>
  import { find, isNil } from "lodash-es"
  import { isAutoSetPrices } from "@/helpers/price-calendar"

  const VALUES_COLORS = [
    {
      min_value: 500,
      max_value: Number.POSITIVE_INFINITY,
      className: "orange"
    },
    {
      min_value: -499,
      max_value: 499,
      className: "transparent"
    },
    {
      min_value: Number.NEGATIVE_INFINITY,
      max_value: -500,
      className: "blue"
    }
  ]

  export default {
    props: {
      currentPrice: Number,
      recommendedPrice: Number
    },

    computed: {
      formattedRecommendedPrice({ recommendedPrice }) {
        return isNil(recommendedPrice) ? "—" : this.$n(recommendedPrice)
      },

      pricesDifference({ recommendedPrice, currentPrice }) {
        return recommendedPrice && currentPrice ? recommendedPrice - currentPrice : 0
      },

      priceColorClassName({ pricesDifference: diff }) {
        // need to use transparent background color for price cells if auto set prices is active
        if (isAutoSetPrices()) return "transparent"

        return find(VALUES_COLORS, ({ min_value, max_value }) => diff >= min_value && diff <= max_value).className
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .recommended-price
    align-items: center
    border-radius: 5px
    display: flex
    justify-content: center
    width: 100%

    &.blue
      color: $default-white
      background-color: $default-blue

    &.orange
      color: $default-white
      background-color: $default-orange

    &.transparent
      background-color: transparent
</style>
